

















































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class FacilityConfigIndexComponent extends BaseComponent {
}

